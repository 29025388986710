import { Observable, of, forkJoin } from 'rxjs';
import api from '@Utils/api';
import { catchError, concatMap, share } from 'rxjs/internal/operators';
import { BillingType, AccountType } from '@ApiModels/accounts';
import { ISinglePackage } from '@ApiModels/singlePackage';
import { ICustomerProducts } from '@ApiModels/customerProducts';
import { IPackage } from '@ApiModels/packages';
import { IProductOutput } from '@ApiModels/customerProducts';
import { IPlanAction, IValidateEbuOffer, IValidatePlan, OrderFlowService } from '@Services/orderFlow/orderFlow';
import { IDropdownItem } from '@Components/controls/Dropdown/Dropdown';
import { IAccount } from '@ApiModels/accounts';
import { IProductByIdItem } from '@ApiModels/productById';
import { ISelectedFilters } from '@Views/ProductPage/subcomponents/Configurator/Configurator';
import { ProfileService } from '@Services/profile/profileService';
import moment from 'moment';
import compareDates from '@Utils/date/compareDates';
import { INSTALLMENTS } from '@ApiModels/utils/attributeGetters';
import { ItemType } from '@Services/productsService/productsService';
import { ICalculatedProduct } from '@ApiModels/calculateInstallment';
export interface ICustomerProductsPayload {
	phoneNumber: string;
	billingType: BillingType;
	accountType?: AccountType;
	productsExtendedInfo?: boolean;
	isBaqati?: boolean;
}
interface ISelectedSku {
	productAllSKU: IProductByIdItem[];
	selectedFilters: ISelectedFilters;
	planPartNumberRaw: string;
	skipInstallments?: boolean;
	isCommitmentBaqatiConfig?: boolean;
}
interface IPayloadParams {
	mobilePhone: string;
	selectedSKU?: IProductByIdItem;
	planToCheck?: ISinglePackage;
	skusName?: string;
	currentPlan?: IProductOutput;
	activeProvisioning?: IProductOutput;
	activePlanCommitment?: IProductOutput;
	isHBB: boolean;
	isWFBB: boolean;
	isHbbInstallmentsOffer?: boolean;
	oldDeviceCommitment?: string;
	sendDeviceCommitment?: boolean;
	isB2b2c?: boolean;
	isB2b?: boolean;
	commitment?: string;
	installmentAmount?: string;
	planAction?: string;
	installmentDuration?: string;
	billingType?: BillingType;
	accountType?: AccountType;
	deviceType?: string[] | string;
	creditRating?: string;
	isStaff: boolean;
}

interface IValidateEbuOfferPayloadParams {
	mobilePhone: string;
	commitment?: string;
	smartVoucherCommitmentId?: string;
	calculatedProduct: ICalculatedProduct;
	onlyDevice?: boolean;
	hasAdditionalInfo: boolean;
	isB2b?: boolean;
}

interface IGetValidatedOffer {
	isStaff: boolean;
	currentPlan?: IProductOutput;
	activeProvisioning?: IProductOutput;
	activePlanCommitment?: IProductOutput;
	profileHasMultiAccount: boolean;
	selectedDropdownItem: IDropdownItem | null;
	selectedAccount: IAccount;
	productAllSKU: IProductByIdItem[];
	selectedFilters: ISelectedFilters;
	isHBB: boolean;
	currentPlanIsWFBB5G: boolean;
	currentPlanIsWFBB4G: boolean;
	validate?: boolean;
	cantBeUpgradedPlanId?: string;
	oldDeviceCommitment?: string;
	isB2b2c?: boolean;
	isB2b?: boolean;
	isCommitmentBaqatiConfig?: boolean;
}
interface IValidatedSelectedPlan {
	isStaff: boolean;
	currentPlan?: IProductOutput;
	activeProvisioning?: IProductOutput;
	activePlanCommitment?: IProductOutput;
	profileHasMultiAccount: boolean;
	selectedDropdownItem: IDropdownItem | null;
	selectedAccount: IAccount;
	productAllSKU: IProductByIdItem[];
	selectedFilters: ISelectedFilters;
	selectedPlan: ISinglePackage;
	isHBB: boolean;
	currentPlanIsWFBB5G: boolean;
	currentPlanIsWFBB4G: boolean;
	validate?: boolean;
	oldDeviceCommitment?: string;
	isB2b2c?: boolean;
	isB2b?: boolean;
}
interface IGetRecommendedAndValidatedPlan {
	isStaff: boolean;
	phoneNumber: string;
	billingType: BillingType;
	currentPlan?: IProductOutput;
	profileHasMultiAccount: boolean;
	selectedDropdownItem: IDropdownItem | null;
	selectedAccount: IAccount;
	comParamId: string;
	isHBB: boolean;
	currentPlanIsWFBB5G: boolean;
	currentPlanIsWFBB4G: boolean;
	skusName: string;
	validate?: boolean;
	oldDeviceCommitment?: string;
	isB2b2c?: boolean;
	isB2b?: boolean;
}
export class PackagesService {
	public static getContentBySearchTerm(term: string): Observable<{ packages: ISinglePackage[] }> {
		return api.eCommerce.getContentBySearchTerm(term).call();
	}

	public static getRecommendedAndValidatedPlan({
		isStaff,
		phoneNumber,
		billingType,
		profileHasMultiAccount,
		selectedDropdownItem,
		selectedAccount,
		currentPlan,
		comParamId,
		skusName,
		validate = true,
		isHBB,
		currentPlanIsWFBB5G,
		currentPlanIsWFBB4G,
		oldDeviceCommitment,
		isB2b2c,
		isB2b,
	}: IGetRecommendedAndValidatedPlan): Observable<{ recommendedPlan?: ISinglePackage | null }> {
		return api.omantelApi
			.getRecommendedPlan(phoneNumber, billingType)
			.withErrorHandling()
			.call()
			.pipe(
				concatMap((r) => {
					if (r.recommendedPlans.length) {
						const productId = String(r.recommendedPlans[0].eformId ?? '');
						return api.eCommerce
							.getPackages(
								isHBB ? 'HBB' : currentPlanIsWFBB5G || currentPlanIsWFBB4G ? 'WFBB' : 'POSTPAID',
								'PLAN'
							)
							.call()
							.pipe(
								concatMap((response: IPackage) => {
									const recommendedPlan = response.packages?.find((singlePackage: ISinglePackage) => {
										const partNumber = String(singlePackage.partNumber);
										const partNumberRaw = String(singlePackage.partNumberRaw);
										return billingType === BillingType.POSTPAID
											? partNumber === productId
											: partNumberRaw === productId;
									});
									if (validate) {
										if (comParamId) {
											return api.omantelShop
												.validateOffer({
													isStaff,
													mobilePhone: !!profileHasMultiAccount
														? selectedDropdownItem?.text ?? ''
														: selectedAccount?.accountNum ?? '',
													commitment: comParamId,
													skus: skusName,
													planAction: currentPlan
														? recommendedPlan?.hierarchyIndex ===
														  currentPlan?.commerceAttributes?.hierarchyIndex
															? getEbuPlanActionByActionKey(
																	'MOBILE_THE_SAME_PLAN',
																	!!isB2b,
																	!!isB2b2c
															  )
															: getEbuPlanActionByActionKey(
																	'MOBILE_PLAN_UPGRADE',
																	!!isB2b,
																	!!isB2b2c
															  )
														: getEbuPlanActionByActionKey(
																'MOBILE_PLAN_UPGRADE',
																!!isB2b,
																!!isB2b2c
														  ),
													oldTariff: '',
													oldDeviceCommitment,
												})
												.call();
										} else {
											return of({ recommendedPlan: undefined });
										}
									} else {
										return of({ recommendedPlan });
									}
								})
							);
					} else {
						return of({ recommendedPlan: undefined });
					}
				})
			);
	}

	public static getCustomerProducts(payload: ICustomerProductsPayload): Observable<ICustomerProducts> {
		return api.omantelApi
			.getCustomerProducts(payload)
			.withErrorHandling()
			.call()
			.pipe(
				concatMap(async (customerProducts: ICustomerProducts) => {
					for (const prod of customerProducts?.products) {
						if (!customerProducts?.activeDeviceAccessoriesInstallmentId) {
							if (prod.group === 'DEVICE_ACCESSORIES')
								customerProducts.activeDeviceAccessoriesInstallmentId = prod.productId;
						}
						if (!customerProducts?.activeMobileDeviceInstallmentId) {
							if (prod.group === 'TABLETS_LAPTOPS')
								customerProducts.activeMobileDeviceInstallmentId = prod.productId;
						}
					}
					if (!payload?.isBaqati) return customerProducts;

					const oldDeviceCommitmentId =
						customerProducts?.products?.find((it) => it.group === 'PROMOTIONS')?.productId || undefined;
					if (oldDeviceCommitmentId) {
						customerProducts.oldDeviceCommitmentId = oldDeviceCommitmentId;
						const contractDetails = await ProfileService.getContractDetails(
							payload?.phoneNumber
						).toPromise();
						const contractStatus = contractDetails.commitmentDetails.find(
							(value) => value.arborProductId === oldDeviceCommitmentId
						)?.status;
						if (contractStatus === 'Active' && contractDetails?.commitmentDetails.length > 0) {
							const contractEndDate =
								contractDetails.commitmentDetails.find(
									(value) => value.arborProductId === oldDeviceCommitmentId
								)?.endDate || undefined;
							if (contractEndDate) {
								const localDateAfterTwoMonths = moment().add(2, 'M');
								customerProducts.oldDeviceCommitmentCanBeExtended = !!(
									compareDates(
										localDateAfterTwoMonths.format('YYYY-MM-DD'),
										moment(contractEndDate).format('YYYY-MM-DD')
									).second.isSmaller ||
									moment(localDateAfterTwoMonths.format('YYYY-MM-DD')).isSame(
										moment(contractEndDate).format('YYYY-MM-DD')
									)
								);
								if (customerProducts.oldDeviceCommitmentCanBeExtended)
									customerProducts.sendDeviceCommitment = true;
							}
						} else {
							customerProducts.oldDeviceCommitmentCanBeExtended = true;
						}
					}
					return customerProducts;
				}),
				share()
			);
	}

	public static getPackagesByGroupAndType(
		type: types.ecommerce.PackageType,
		group: types.ecommerce.PackageGroup
	): Observable<IPackage> {
		return api.eCommerce.getPackages(group, type).disableCache().withErrorHandling().call();
	}

	public static getValidatedOffers({
		isStaff,
		currentPlan,
		activeProvisioning,
		activePlanCommitment,
		profileHasMultiAccount,
		selectedDropdownItem,
		selectedAccount,
		productAllSKU,
		selectedFilters,
		isHBB,
		currentPlanIsWFBB5G,
		currentPlanIsWFBB4G,
		validate = true,
		oldDeviceCommitment,
		isB2b2c,
		isB2b,
		isCommitmentBaqatiConfig,
	}: IGetValidatedOffer): Observable<(ISinglePackage | null)[]> {
		const currentPlanIsWFBB = currentPlanIsWFBB5G || currentPlanIsWFBB4G;
		return api.eCommerce
			.getPackages(isHBB ? 'HBB' : currentPlanIsWFBB5G || currentPlanIsWFBB4G ? 'WFBB' : 'POSTPAID', 'PLAN')
			.withErrorHandling()
			.disableCache()
			.call()
			.pipe(
				concatMap((plans) => {
					let properPlans = [];
					if (isHBB) {
						properPlans = (plans as IPackage).hbbPlansToSubscribe;
					} else if (currentPlanIsWFBB5G) {
						properPlans = (plans as IPackage).wfbb5GPlansToSubscribe;
					} else if (currentPlanIsWFBB4G) {
						properPlans = (plans as IPackage).wfbb4GPlansToSubscribe;
					} else {
						properPlans = (plans as IPackage).plansToSubscribe;
					}
					const fromSmallestToLargest = properPlans.sort(
						(prev: ISinglePackage, next: ISinglePackage) => prev.hierarchyIndex - next.hierarchyIndex
					);
					const onlyHigherPlansThanUserCurrentPlan = fromSmallestToLargest.filter(
						(plan: ISinglePackage) =>
							plan.hierarchyIndex >= (currentPlan?.commerceAttributes?.hierarchyIndex ?? 0)
					);

					if (validate) {
						let promisesArray: Promise<ISinglePackage | null>[] = [];
						onlyHigherPlansThanUserCurrentPlan.forEach((sortedPlan) => {
							const selectedSKU = getSelectedSKU({
								productAllSKU,
								selectedFilters,
								planPartNumberRaw: sortedPlan.partNumberRaw,
								skipInstallments: true,
							});

							const skusName = getSelectedSKUName(productAllSKU, selectedFilters);
							const comParamId = selectedSKU?.comParamId;
							const mobilePhone = !!profileHasMultiAccount
								? selectedDropdownItem?.text ?? ''
								: selectedAccount?.accountNum ?? '';

							if (comParamId) {
								promisesArray = [
									...promisesArray,
									new Promise((resolve) => {
										OrderFlowService.validateOffer(
											getPayload({
												isStaff,
												mobilePhone,
												selectedSKU,
												planToCheck: sortedPlan,
												currentPlan,
												skusName,
												activeProvisioning,
												activePlanCommitment,
												isHBB,
												isWFBB: currentPlanIsWFBB,
												oldDeviceCommitment,
												isB2b2c,
												isB2b,
											})
										).subscribe(
											(r) => {
												if (r.valid) {
													resolve(sortedPlan);
												} else {
													resolve(null);
												}
											},
											(e) => {
												resolve(null);
											}
										);
									}),
								];
							}
						});
						return promisesArray.length ? forkJoin(promisesArray) : of([]);
					} else {
						const availablePlans = onlyHigherPlansThanUserCurrentPlan.filter((item) => {
							const selectedSKU = getSelectedSKU({
								productAllSKU,
								selectedFilters,
								planPartNumberRaw: item?.partNumberRaw,
								skipInstallments: true,
								isCommitmentBaqatiConfig,
							});
							return selectedSKU && (selectedSKU?.comParamId || isCommitmentBaqatiConfig);
						});
						return of(availablePlans || null);
					}
				})
			);
	}

	public static getHigherValidatedPlan({
		isStaff,
		currentPlan,
		activeProvisioning,
		activePlanCommitment,
		profileHasMultiAccount,
		selectedDropdownItem,
		selectedAccount,
		productAllSKU,
		selectedFilters,
		isHBB,
		currentPlanIsWFBB5G,
		currentPlanIsWFBB4G,
		validate = true,
		cantBeUpgradedPlanId,
		oldDeviceCommitment,
		isB2b2c,
		isB2b,
	}: IGetValidatedOffer): Observable<ISinglePackage | null> {
		const currentPlanIsWFBB = currentPlanIsWFBB5G || currentPlanIsWFBB4G;
		return api.eCommerce
			.getPackages(isHBB ? 'HBB' : currentPlanIsWFBB ? 'WFBB' : 'POSTPAID', 'PLAN')
			.withErrorHandling()
			.disableCache()
			.call()
			.pipe(
				concatMap((plans) => {
					let properPlans = [];
					if (isHBB) {
						properPlans = (plans as IPackage).hbbPlansToSubscribe;
					} else if (currentPlanIsWFBB5G) {
						properPlans = (plans as IPackage).wfbb5GPlansToSubscribe;
					} else if (currentPlanIsWFBB4G) {
						properPlans = (plans as IPackage).wfbb4GPlansToSubscribe;
					} else {
						properPlans = (plans as IPackage).plansToSubscribe;
					}

					const fromSmallestToLargest = properPlans.sort(
						(prev: ISinglePackage, next: ISinglePackage) => prev.hierarchyIndex - next.hierarchyIndex
					);
					const higherPlansThanUserCurrentPlan = fromSmallestToLargest.filter(
						(plan: ISinglePackage) =>
							plan.hierarchyIndex > (currentPlan?.commerceAttributes?.hierarchyIndex ?? 0)
					);

					if (cantBeUpgradedPlanId) {
						const currentUserPlan = fromSmallestToLargest.filter(
							(singlePlan) => singlePlan.partNumberRaw === cantBeUpgradedPlanId
						);

						const availablePlan = currentUserPlan.find((item) => {
							const selectedSKU = getSelectedSKU({
								productAllSKU,
								selectedFilters,
								planPartNumberRaw: item?.partNumberRaw,
							});

							return selectedSKU && selectedSKU?.comParamId;
						});

						return of(availablePlan || null);
					} else {
						if (higherPlansThanUserCurrentPlan.length) {
							if (validate) {
								const validate = (planIndex: number): Observable<ISinglePackage | null> => {
									const higherPlan = higherPlansThanUserCurrentPlan[planIndex];
									const selectedSKU = getSelectedSKU({
										productAllSKU,
										selectedFilters,
										planPartNumberRaw: higherPlan?.partNumberRaw,
									});
									const skusName = getSelectedSKUName(productAllSKU, selectedFilters);
									const comParamId = selectedSKU?.comParamId;
									const mobilePhone = !!profileHasMultiAccount
										? selectedDropdownItem?.text ?? ''
										: selectedAccount?.accountNum ?? '';

									if (selectedSKU) {
										if (comParamId) {
											return OrderFlowService.validateOffer(
												getPayload({
													isStaff,
													mobilePhone,
													selectedSKU,
													planToCheck: higherPlan,
													currentPlan,
													skusName,
													activeProvisioning,
													activePlanCommitment,
													isHBB,
													isWFBB: currentPlanIsWFBB,
													oldDeviceCommitment,
													isB2b2c,
													isB2b,
												})
											)
												.pipe(
													concatMap((r) => {
														if (r.valid) {
															return of(higherPlan);
														}
														return of(null);
													})
												)
												.pipe(
													catchError(() => {
														return validate(planIndex + 1);
													})
												);
										} else {
											return of(null);
										}
									} else {
										if (planIndex < higherPlansThanUserCurrentPlan.length) {
											return validate(planIndex + 1);
										} else {
											return of(null);
										}
									}
								};
								return validate(0); // get higher plan than user have, which is validated properly
							} else {
								const availablePlan = higherPlansThanUserCurrentPlan.find((item) => {
									const selectedSKU = getSelectedSKU({
										productAllSKU,
										selectedFilters,
										planPartNumberRaw: item?.partNumberRaw,
									});

									return selectedSKU && selectedSKU?.comParamId;
								});
								return of(availablePlan || null);
							}
						} else {
							return of(fromSmallestToLargest[fromSmallestToLargest.length - 1]);
						}
					}
				})
			);
	}

	public static validateSelectedPlan({
		isStaff,
		currentPlan,
		activeProvisioning,
		activePlanCommitment,
		profileHasMultiAccount,
		selectedDropdownItem,
		selectedAccount,
		productAllSKU,
		selectedFilters,
		selectedPlan,
		isHBB,
		currentPlanIsWFBB5G,
		currentPlanIsWFBB4G,
		validate = true,
		oldDeviceCommitment,
		isB2b2c,
		isB2b,
	}: IValidatedSelectedPlan): Observable<ISinglePackage | null> {
		if (validate) {
			const selectedSKU = getSelectedSKU({
				productAllSKU,
				selectedFilters,
				planPartNumberRaw: selectedPlan.partNumberRaw,
			});
			const skusName = getSelectedSKUName(productAllSKU, selectedFilters);
			const comParamId = selectedSKU?.comParamId;
			const mobilePhone = !!profileHasMultiAccount
				? selectedDropdownItem?.text ?? ''
				: selectedAccount?.accountNum ?? '';

			if (comParamId) {
				return OrderFlowService.validateOffer(
					getPayload({
						isStaff,
						mobilePhone,
						selectedSKU,
						planToCheck: selectedPlan,
						currentPlan,
						skusName,
						activeProvisioning,
						activePlanCommitment,
						isHBB,
						isWFBB: currentPlanIsWFBB5G || currentPlanIsWFBB4G,
						oldDeviceCommitment,
						isB2b2c,
						isB2b,
					})
				)
					.pipe(
						concatMap((r) => {
							if (r.valid) {
								return of(selectedPlan);
							}
							return of(null);
						})
					)
					.pipe(
						catchError(() => {
							return of(null);
						})
					);
			} else {
				return of(null);
			}
		} else {
			return of(selectedPlan);
		}
	}
}

export const getValidateOfferPayload = ({
	mobilePhone,
	commitment,
	smartVoucherCommitmentId,
	calculatedProduct,
	onlyDevice,
	hasAdditionalInfo,
	isB2b,
}: IValidateEbuOfferPayloadParams): IValidateEbuOffer => {
	return {
		mobilePhone,
		commitment,
		smartVoucherCommitmentId,
		planAction: onlyDevice
			? isB2b
				? 'B2B_WITHOUT_VOUCHER_UPFRONT'
				: 'B2B2C_WITHOUT_VOUCHER_UPFRONT'
			: Number(calculatedProduct?.device?.deviceCommitments?.installmentMonths)
			? isB2b
				? 'B2B_WITH_VOUCHER_INSTALL'
				: 'B2B2C_WITH_VOUCHER_INSTALL'
			: isB2b
			? 'B2B_WITH_VOUCHER_UPFRONT'
			: 'B2B2C_WITH_VOUCHER_UPFRONT',
		installmentAmount: calculatedProduct?.device?.deviceCommitments?.installmentAmountBz || '0',
		hasAdditionalInfo,
	};
};

export const getPayload = ({
	mobilePhone,
	selectedSKU,
	planToCheck,
	currentPlan,
	skusName,
	activeProvisioning,
	activePlanCommitment,
	isHBB,
	isWFBB,
	isHbbInstallmentsOffer,
	oldDeviceCommitment,
	sendDeviceCommitment,
	isB2b2c,
	isB2b,
	commitment,
	installmentAmount,
	installmentDuration,
	billingType,
	accountType,
	deviceType,
	creditRating,
	isStaff,
}: IPayloadParams): IValidatePlan => {
	const comParamId = selectedSKU?.comParamId;
	const tariff = selectedSKU?.planDetails.planId;
	const theSamePlan = isHbbInstallmentsOffer
		? true
		: planToCheck?.hierarchyIndex === currentPlan?.commerceAttributes?.hierarchyIndex;
	const oldTariff = currentPlan?.productId;
	const oldProvisioning = activeProvisioning?.productId;
	const oldPlanCommitment = activePlanCommitment?.productId;
	const provisioning = planToCheck?.provisioningID;
	const planCommitment = isWFBB ? planToCheck?.wfbbCommitment : planToCheck?.planCommitment;
	const accessoryWithPlan = selectedSKU?.defItemType === ItemType.ITEM_ACCESSORY_INSTALMENTS;
	const accessoryWithHBBPlan = selectedSKU?.defItemType === ItemType.ITEM_ACCESSORY_INSTALMENTS_HBB;
	const accessoryWithWFBBPlan = selectedSKU?.defItemType === ItemType.ITEM_ACCESSORY_INSTALMENTS_WFBB;
	const mobileDeviceWithPlan = selectedSKU?.defItemType === ItemType.TABLETS_LAPTOPS_INSTALMENTS;
	const mobileDeviceWithHBBPlan = selectedSKU?.defItemType === ItemType.TABLETS_LAPTOPS_INSTALMENTS_HBB;
	const mobileDeviceWithWFBBPlan = selectedSKU?.defItemType === ItemType.TABLETS_LAPTOPS_INSTALMENTS_WFBB;
	const getPlanAction = () => {
		if (mobileDeviceWithHBBPlan) {
			return 'TABLETS_LAPTOPS_INSTALMENTS_HBB';
		} else if (mobileDeviceWithWFBBPlan) {
			return 'TABLETS_LAPTOPS_INSTALMENTS_WFBB';
		} else if (accessoryWithHBBPlan) {
			return 'ITEM_ACCESSORY_INSTALMENTS_HBB';
		} else if (accessoryWithWFBBPlan) {
			return 'ITEM_ACCESSORY_INSTALMENTS_WFBB';
		} else if (isHBB && theSamePlan) {
			return 'HBB_THE_SAME_PLAN';
		} else if (isWFBB && theSamePlan) {
			return 'WFBB_THE_SAME_PLAN';
		} else if (accessoryWithPlan) {
			return getEbuPlanActionByActionKey('ITEM_ACCESSORY_INSTALMENTS', !!isB2b, !!isB2b2c);
		} else if (mobileDeviceWithPlan) {
			return getEbuPlanActionByActionKey('TABLETS_LAPTOPS_INSTALMENTS', !!isB2b, !!isB2b2c);
		} else if (theSamePlan) {
			return getEbuPlanActionByActionKey('MOBILE_THE_SAME_PLAN', !!isB2b, !!isB2b2c);
		} else {
			return getEbuPlanActionByActionKey('MOBILE_PLAN_UPGRADE', !!isB2b, !!isB2b2c);
		}
	};
	const oldDeviceCommitmentValue = !accessoryWithPlan && !mobileDeviceWithPlan ? oldDeviceCommitment : undefined;
	let payload: IValidatePlan;
	(isHBB || isWFBB) && !theSamePlan
		? (payload = {
				mobilePhone,
				tariff: tariff || '',
				provisioning,
				planCommitment,
				commitment: comParamId ?? '',
				oldTariff,
				oldProvisioning,
				oldPlanCommitment,
				planAction: isWFBB ? 'WFBB_PLAN_UPGRADE' : 'HBB_PLAN_UPGRADE',
				skus: skusName ?? '',
				installmentDuration,
				billingType,
				accountType,
				deviceType,
				creditRating,
				isStaff,
		  })
		: (payload = {
				mobilePhone,
				commitment: !!installmentAmount ? commitment ?? '' : comParamId ?? '',
				skus: skusName ?? '',
				planAction: getPlanAction(),
				oldTariff: '',
				installmentAmount,
				installmentDuration,
				billingType,
				accountType,
				deviceType,
				creditRating,
				isStaff,
		  });

	if (sendDeviceCommitment) {
		payload = { ...payload, oldDeviceCommitment: oldDeviceCommitmentValue };
	}

	return payload;
};

const getSelectedSKU = ({
	productAllSKU,
	selectedFilters,
	planPartNumberRaw,
	skipInstallments = false,
	isCommitmentBaqatiConfig = false,
}: ISelectedSku) => {
	return productAllSKU.find((sku) => {
		if (INSTALLMENTS.includes(sku.defItemType) && sku.planDetails.planId === planPartNumberRaw) {
			return true;
		}
		return (
			sku.colorId === selectedFilters.selectedColorId &&
			sku.capacityId === selectedFilters.selectedValueId &&
			(sku.installmentPeriod === selectedFilters.installmentPeriod || skipInstallments) &&
			(sku.planDetails.planId === planPartNumberRaw || isCommitmentBaqatiConfig)
		);
	});
};

export const getSelectedSKUName = (
	productAllSKU: IProductByIdItem[],
	selectedFilters: ISelectedFilters
): string | undefined => {
	return productAllSKU.find(
		(sku) => sku.colorId === selectedFilters.selectedColorId && sku.capacityId === selectedFilters.selectedValueId
	)?.partNumber;
};

export const getEbuPlanActionByActionKey = (pa: IPlanAction, isB2b: boolean, isB2b2c: boolean): IPlanAction => {
	if (pa === 'ITEM_ACCESSORY_INSTALMENTS') {
		if (isB2b) {
			return 'B2B_ITEM_ACCESSORY_BAQATI';
		} else if (isB2b2c) {
			return 'B2B2C_ITEM_ACCESSORY_BAQATI';
		}
	} else if (pa === 'TABLETS_LAPTOPS_INSTALMENTS') {
		if (isB2b) {
			return 'B2B_TABLETS_LAPTOPS_BAQATI';
		} else if (isB2b2c) {
			return 'B2B2C_TABLETS_LAPTOPS_BAQATI';
		}
	} else if (pa === 'MOBILE_THE_SAME_PLAN') {
		if (isB2b) {
			return 'B2B_MOBILE_THE_SAME_PLAN_BAQATI';
		} else if (isB2b2c) {
			return 'B2B2C_MOBILE_THE_SAME_PLAN_BAQATI';
		}
	} else if (pa === 'MOBILE_PLAN_UPGRADE') {
		if (isB2b) {
			return 'B2B_MOBILE_PLAN_UPGRADE_BAQATI';
		} else if (isB2b2c) {
			return 'B2B2C_MOBILE_PLAN_UPGRADE_BAQATI';
		}
	}
	return pa;
};

export default PackagesService;
