interface IItem {
	name: string;
	value: string;
}
interface ICustomerAccount {
	customer: {
		characteristic: IItem[];
	};
}

export const OMANTEL_ACCOUNT_GROUP_CAT_9 = '9';

const isEmployeeCategory = ({ name, value }: IItem): boolean =>
	name === 'ACCOUNT_CATEGORY' && value === OMANTEL_ACCOUNT_GROUP_CAT_9;

const isEmployee = (customerAccountData: ICustomerAccount | ICustomerAccount[] | Record<string, never>): boolean => {
	const employeeData = Array.isArray(customerAccountData) ? customerAccountData[0] : customerAccountData;
	return !!employeeData?.customer?.characteristic?.find(isEmployeeCategory);
};

export default isEmployee;
