import { Action, ActionFunction0, ActionFunction1, createAction } from 'redux-actions';

export interface IAuthentication {
	signedIn?: boolean;
	ltpaToken2?: string;
	loginType?: ILoginType['payload'];
	telesales?: boolean;
	telemarketer?: boolean;
	userId?: string;
	isUserEmployee?: boolean;
	isMasterProfile?: boolean;
	isB2b?: boolean;
	isB2b2c?: boolean;
	isApplePayAllow?: boolean;
}

export interface ILoginType {
	payload: 'guest' | 'user' | undefined;
}

export interface IUserId {
	payload: string;
}

export interface IIsMasterProfile {
	payload: boolean;
}

export interface IIsUserEmployee {
	payload: boolean;
}

export interface IB2bUserType {
	payload: boolean;
}

export interface IB2b2cUserType {
	payload: boolean;
}

export interface IApplePayAllowType {
	payload: boolean;
}

export const signIn = createAction('SIGN_IN', (action: IAuthentication) => action);

export const logOut: ActionFunction0<any> = createAction('LOG_OUT');

export const setLoginType: ActionFunction1<ILoginType['payload'], Action<ILoginType['payload']>> = createAction(
	'SET_LOGIN_TYPE',
	(action: ILoginType['payload']) => action
);

export const setUserId: ActionFunction1<IUserId['payload'], Action<IUserId['payload']>> = createAction(
	'SET_USER_ID',
	(action: IUserId['payload']) => action
);

export const setIsMasterProfile: ActionFunction1<
	IIsMasterProfile['payload'],
	Action<IIsMasterProfile['payload']>
> = createAction('SET_IS_MASTER_PROFILE', (action: IIsMasterProfile['payload']) => action);

export const setIsUserEmployee: ActionFunction1<
	IIsUserEmployee['payload'],
	Action<IIsUserEmployee['payload']>
> = createAction('SET_IS_USER_EMPLOYEE', (action: IIsUserEmployee['payload']) => action);

export const setB2bUser: ActionFunction1<IB2bUserType['payload'], Action<IB2bUserType['payload']>> = createAction(
	'SET_B2B_USER',
	(action: IB2bUserType['payload']) => action
);

export const setB2b2cUser: ActionFunction1<IB2b2cUserType['payload'], Action<IB2b2cUserType['payload']>> = createAction(
	'SET_B2B2C_USER',
	(action: IB2b2cUserType['payload']) => action
);

export const setApplePayAllow: ActionFunction1<
	IApplePayAllowType['payload'],
	Action<IApplePayAllowType['payload']>
> = createAction('SET_APPLEPAY_SESSION', (action: IApplePayAllowType['payload']) => action);
